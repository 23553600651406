
exports.onClientEntry = () => {

    if( window.location.pathname !== '/maintenance' && window.location.pathname !== '/maintenance/'){
 
      window.location = '/maintenance';
      console.log(window.location.pathname );
      console.log(process.env.IN_SITE_MAINTENANCE )
 
    }
 
  }

  exports.onRouteUpdate = () => {

    if( window.location.pathname !== '/maintenance' && window.location.pathname !== '/maintenance/'){
 
      window.location = '/maintenance';
 
    }
 
  }
  